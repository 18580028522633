<template>
  <Layout class="LogOfScore">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        :rules="rules"
      >
        <el-form-item>
          <el-input
            v-model="formData.userId"
            placeholder="用户ID"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="formData.gameKeyword"
            placeholder="游戏名称"
            clearable
          />
        </el-form-item>
        <el-form-item prop="idcId">
          <el-select
            v-model="formData.idcId"
            v-loading="waitting"
            clearable
            :disabled="waitting"
            placeholder="请选择机房"
          >
            <el-option
              v-for="item in idcList"
              :key="item.idcId"
              :label="item.idcTitle + `(${item.idcId})`"
              :value="item.idcId"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="primary"
          @click="handleExport"
        >
          导出
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="logListInfo.loading"
      :data="logListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template v-slot="{row}">
          <el-form
            :model="row"
            label-position="right"
            class="table-expand"
            label-width="160px"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="游戏问题反馈"
                  style="margin-bottom: 10px;"
                >
                  <div
                    v-for="(item, index) in (row.gameFaqsList || [])"
                    :key="index"
                    class="val"
                  >
                    {{ item }}
                  </div>
                </el-form-item>

                <el-form-item label="操作问题反馈">
                  <div
                    v-for="(item, index) in (row.operateFaqsList || [])"
                    :key="index"
                    class="val"
                  >
                    {{ item }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户邮箱">
                  <span>{{ row.userEmail || '-' }}</span>
                </el-form-item>
                <el-form-item label="游戏名称">
                  <span> {{ row.gameName || '-' }}</span>
                </el-form-item>
                <el-form-item label="平均延迟">
                  <span>{{ row.delayAvg || '-' }}</span>
                </el-form-item>
                <el-form-item label="机房信息">
                  <span>{{ row.idcId || '-' }}</span>
                </el-form-item>
                <el-form-item label="提交时间">
                  <span>{{ row.createTime || '-' }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="自定义评论">
                  <div class="val">
                    {{ row.feedback || '--' }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        prop="userId"
        label="用户ID"
        min-width="170"
      />
      <el-table-column
        prop="scheduleId"
        label="调度ID"
        min-width="170"
      />
      <el-table-column
        prop="gameCount"
        label="游戏次数"
        min-width="100"
        sortable
      />
      <el-table-column
        prop="gameExperienceScore"
        label="游戏体验评分"
        min-width="130"
        sortable
      />
      <el-table-column
        prop="gameQualityScore"
        label="游戏质量评分"
        min-width="130"
        sortable
      />
      <el-table-column
        prop="gameUseTime"
        label="游戏时长"
      />
    </el-table>
    <Pagination
      :total="logListInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.pageSize"
      @pagination="queryData"
    />
  </Layout>
</template>

<script>
import { queryIdcList } from '@/api/machine.js'
import { cloneDeep } from 'lodash'
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import { batchQueryParamsForExport } from '@/utils'

import {
  queryFeedbacks
} from '@/api/netboom_score.js'

export default {
  name: 'LogOfScore',
  data () {
    return {
      formData: {
        page: 1,
        pageSize: 20
      },
      rules: {},
      idcList: [],
      waitting: false,
      logListInfo: {
        list: [],
        loading: false,
        total: 0
      },
      batchUploadTemplate: {
        list: [],
        header: [
          '用户ID',
          '调度ID',
          '游戏次数',
          '游戏体验评分',
          '游戏质量评分',
          '游戏时长',

          '游戏问题反馈',
          '操作问题反馈',

          '用户邮箱',
          '游戏名称',
          '平均延迟',
          '机房信息',
          '提交时间',

          '自定义评论'
        ],
        fields: [
          'userId',
          'scheduleId',
          'gameCount',
          'gameExperienceScore',
          'gameQualityScore',
          'gameUseTime',

          'gameFaqsList',
          'operateFaqsList',

          'userEmail',
          'gameName',
          'delayAvg',
          'idcId',
          'createTime',

          'feedback'
        ]
      }
    }
  },
  created () {
    this.queryIdcList()
    this.handleSearch()
  },
  methods: {
    queryIdcList () {
      queryIdcList({})
        .then(res => {
          this.idcList = res.data.list
        })
    },
    handleSearch () {
      this.formData.page = 1
      this.queryData()
    },
    queryData () {
      this.logListInfo.loading = true
      const formData = cloneDeep(this.formData)
      queryFeedbacks({
        ...formData
      })
        .then(res => {
          this.logListInfo.list = res.data.list
          this.logListInfo.total = res.data.total
        })
        .finally(() => {
          this.logListInfo.loading = false
        })
    },
    queryLogListForExport () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.logListInfo.loading = true
            const formData = cloneDeep(this.formData)

            const paramsList = batchQueryParamsForExport({
              params: {
                ...formData,
                pageSize: 100
              },
              total: this.logListInfo.total
            })
            Promise.all(paramsList.map(params => queryFeedbacks(params)))
              .then(resultList => {
                const result = resultList.reduce((res, item) => {
                  return res.concat(item.data.list)
                }, [])
                resolve(result)
              })
              .finally(() => {
                this.logListInfo.loading = false
              })
          } else {
            resolve(false)
          }
        })
      })
    },
    handleExport () {
      this.queryLogListForExport()
        .then(data => {
          if (data) {
            this.formatToDwonload(data)
            this.downloadExcel()
          }
        })
    },
    formatToDwonload (data) {
      const temp = cloneDeep(data)
      temp.forEach(row => {
        row.gameFaqsList = (row.gameFaqsList || []).join(',')
        row.operateFaqsList = (row.operateFaqsList || []).join(',')

        row.userEmail = row.userEmail || '-'
        row.gameName = row.gameName || '-'
        row.delayAvg = row.delayAvg || '-'
        row.idcId = row.idcId || '-'
        row.createTime = row.createTime || '-'

        row.feedback = row.feedback || '-'
      })
      this.batchUploadTemplate.list = temp
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, '机器使用日志' + '_' + new Date().toLocaleDateString())
    }
  }
}
</script>

<style lang="less">
  .LogOfScore{
    .el-table__expanded-cell{
      background-color: #FAFCFD;
      .table-expand{
        label {
          width: 100px;
          color: #99a9bf;
        }
        .val{
          line-height: 26px;
          transform: translateY(5px);
        }
        .el-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
</style>
